.modal {
  display: none;
}
.active {
  display: flex;
}
.PrintModalButton {
  display: none;
}
.PrintModalButtonSend {
  margin-right: 10px;
}
