@import '../../../node_modules/ag-grid-community/src/styles/ag-grid';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-default-params';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-font-vars';
@import '../../../node_modules/ag-grid-community/src/styles/mixins/ag-theme-mixins';
@import '../../../node_modules/ag-grid-community/src/styles/webfont/agGridMaterialFont';

//colors
$header-font-color: #1b3548;
$cell-font-color: #495e5f;
$cell-background: #ffffff;
$cell-backdrop-filter: blur(18px);
$paragraph: #495e5f;
$defaultLight: #f1f3f6;
$defaultLighter: #ffffff;
$primary: #008291;
$neutralDark: #c1cbd8;
$background-opacity: rgba(255, 255, 255, 0.8);

//sizes
$header-row-height: 40px;
$margin-right-body: 4px;
$width-header: 340px;
$regular-cell-height: 30px;
$header-cell-height: 40px;
$padding-viewport: 0px 7px 0px 8px;
$row-border-radius: 8px;
$cell-box-shadow: 0px 2px 8px rgba(0, 130, 145, 0.08);
$bottom-bottom-radius: 8px;
$defaultBorderRadius: 8px;

.ag-theme-custom-base {
  @include ag-theme-material(
    (
      grid-size: 8px,
      icon-size: 16px,
    )
  );
  .ag-header-row {
    height: $header-row-height;
  }
  .ag-header-cell {
    font-weight: bold;
    color: $header-font-color;
    width: $width-header;
    height: $header-cell-height;
  }
  .ag-header-icon {
    color: inherit;
  }
  .ag-row {
    height: $regular-cell-height;
  }
  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $cell-font-color;
    line-height: $regular-cell-height;
    &-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: $regular-cell-height;
    }
  }
  .ag-column-drop-horizontal {
    //ряд для группировки
    height: $regular-cell-height;
  }
  .ag-cell-label-container {
    display: flex;
  }
  .ag-header-cell-label {
    align-items: center;
    justify-content: center;
  }
}

.ag-side-buttons {
  display: none;
}

.ag-grid-theme-custom-map {
  .ag-body-viewport {
    padding: $padding-viewport;
    margin-right: $margin-right-body;
  }

  .ag-header-cell-label {
    justify-content: left;
  }
  .ag-root-wrapper {
    background: none;
    border-bottom-left-radius: $bottom-bottom-radius;
    border-bottom-right-radius: $bottom-bottom-radius;
  }
  .ag-cell-range-selected-1 {
    box-shadow: $cell-box-shadow;
    background: $cell-background;
    border-radius: $row-border-radius;
    border-color: $cell-background !important;
  }

  .ag-column-drop-empty {
    display: none;
  }
  .ag-cell {
    color: $paragraph;
    padding-left: 7px;
    justify-content: left;
    border-radius: $defaultBorderRadius;
  }
  .ag-header {
    border: none;
    background: rgba(255, 255, 255, 0.01);
  }
  .ag-header-cell-sortable {
    padding-left: 16px;
    padding-right: 11px;
  }
  .ag-header-label-icon.ag-sort-order {
    display: none;
  }
  .ag-row-hover {
    background: $defaultLight !important;
    border-radius: $defaultBorderRadius !important;
    transition: all 0.3s;
  }
  .ag-row-selected {
    background: $defaultLight !important;
    border-radius: $defaultBorderRadius !important;
  }

  .ag-row-even {
    background: $defaultLighter;
    border-radius: $defaultBorderRadius;
  }

  .ag-row-selected .ag-cell-value {
    color: $primary;
  }

  .ag-row {
    border-top-style: none !important;
  }

  .ag-horizontal-right-spacer.ag-scroller-corner {
    display: none;
  }

  .ag-root ::-webkit-scrollbar {
    width: 6px;
    background: none;
  }

  //.ag-root:hover ::-webkit-scrollbar {
  //  width: 8px !important;
  //  background: none;
  //
  //  -webkit-transition: all 0.8s;
  //}

  //.ag-root:hover ::-webkit-scrollbar-thumb {
  //  background: $neutralDark;
  //  border-radius: $defaultBorderRadius;
  //  -webkit-transition: all 0.8s;
  //}

  .ag-root ::-webkit-scrollbar-track {
    border: none;
    border-radius: $defaultBorderRadius;
  }

  .ag-root ::-webkit-scrollbar-thumb {
    background: $neutralDark;
    border-radius: $defaultBorderRadius;
  }

  .ag-body-horizontal-scroll ::-webkit-scrollbar {
    height: 6px !important;
    background: $background-opacity;
    background: none;
  }

  //.ag-body-horizontal-scroll:hover ::-webkit-scrollbar{
  //  height: 8px !important;
  //  background: $background-opacity;
  //  background: none;
  //  transition: all 0.3s;
  //}

  .ag-body-horizontal-scroll ::-webkit-scrollbar-track {
    border: none;
    width: 6px !important;
    border-radius: $defaultBorderRadius;
  }

  .ag-body-horizontal-scroll {
    height: 6px !important;
    min-height: 6px !important;
    max-height: 6px !important;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 10px;
  }
  .ag-body-horizontal-scroll-viewport {
    height: 10px !important;
    min-height: 10px !important;
    max-height: 10px !important;
  }

  // Todo use style in themes Тех-долг-FRONTEND #5632
  //Todo transition for scrollbar Тех-долг-FRONTEND #5632
}
