@import 'scss/mixins';
@import 'scss/ag-grid';
@import 'scss/library-overwrite';

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Heavy'), url(../fonts/gilroy/Gilroy-Bold.woff) format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Heavy'), url(../fonts/gilroy/Gilroy-Semibold.woff) format('woff');
  font-weight: 600;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', sans-serif, monospace;
}

.ant-select-dropdown {
  z-index: 5000;
}

.ant-dropdown {
  z-index: 7500;
}

.ant-collapse-content {
  overflow: visible;
}

.ant-layout-content {
  width: 100%;
  height: 100vh;
  position: relative;
}

.ant-layout-sider {
  background-color: rgba(255, 255, 255, 0);
}

.ant-layout {
  background: #f1f3f6;
  position: relative;
}

.ant-layout-sider-children {
  height: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: #fff;
}

.ant-layout-header {
  position: absolute;
  top: 16px;
  right: 16px;
  height: max-content;
  z-index: 3;
  width: calc(100% - 105px);
  background: none !important;
  line-height: 0;
  padding: 0;
  transition: 0.2s;
}

.header__with-map {
  width: calc(100% - 315px);
}

.header__without-map {
  width: calc(100% - 35px);
}

.hidden {
  display: none !important;
}

.styledWrapper {
  margin: 0 20px;
  width: calc(100% / 12 * 4 - 40px);
  min-width: 250px;
  justify-content: flex-start;
  position: relative;
  & .ant-form-item {
    width: 100%;
  }
}
.workInProgress {
  display: none !important;
}

.ant-select-dropdown-menu {
  max-height: 166px;
  border-radius: 0 0 8px 8px;
}

.ant-select.ant-select-open.ant-select-enabled.ant-select-focused {
  border-radius: 8px 8px 0 0;
}

.ant-select-dropdown.ant-select-dropdown--single {
  width: inherit;
  //font-family: AvantGardeGothicC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 130, 145, 0.08);
  background: #f1f3f6;

  ::-webkit-scrollbar {
    width: 6px;
    color: #880000;
    background: #f1f3f6;
  }
  ::-webkit-scrollbar-track {
    border: none;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c1cbd8;
    border-radius: 8px;
  }
}

//temp

.layers-wrapper {
  position: absolute;
  left: 20px;
  bottom: 20px;
  top: 90px;
  width: fit-content;
  z-index: 1;
}

.mapTool-wrapper {
  position: absolute;
  right: 20px;
  top: 90px;
  height: 90%;
  width: 52px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.vehicles-wrapper {
  margin-top: 100px;
}

.gridTable-wrapper {
  padding-top: 80px;
}

.mapSelect-wrapper {
  padding-top: 100px;
}

.timePlayer-wrapper {
  top: 90px;
}

.objectPanel-wrapper {
  margin-top: 80px;
}

.groupPanel-wrapper {
  margin-top: 80px;
}

.ant-tooltip-inner {
  height: 40px;
  border-radius: 16px;
  background-color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  color: #56697a;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.ant-tooltip-arrow::before {
  background-color: #fff;
}
