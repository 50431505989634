p {
  margin-top: 0;
  margin-bottom: 0;
}

.PrintMenu {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: -10px;
  left: 0;

  & .ant-select {
    &-selection {
      border: none !important;
      background: #f1f3f6 !important;
      border-radius: 8px !important;
      border-right-width: 2px !important;
      &--single {
        height: 40px;
      }
      &__rendered {
        line-height: 40px !important;
      }
      &-selected-value {
        font-family: Gilroy, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: #495a82;
        line-height: 40px;
      }
    }
    &-arrow {
      color: #6b8ae4 !important;
    }
    &-open {
      font-family: Gilroy, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #495a82;
      line-height: 40px;
      background-color: #fff !important;
    }
  }

  & .ant-select-open .ant-select-selector {
    box-shadow: 0 2px 8px rgba(107, 138, 228, 0.12) !important;
    border: 2px solid #6b8ae4 !important;
    &:hover {
      border-right-width: 2px !important;
    }
  }

  & .ant-select-selector .ant-select-selection--single {
    box-shadow: none !important;
  }

  & .ant-select .ant-select-open {
    box-shadow: none;
    background-color: #fff;
  }
}

.PrintMenuSelect {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #ffffff !important;
  border-radius: 8px;
  padding: 10px 10px 10px 14px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  border: none;
  color: #929fb5;
  outline: none;
}

.setBackground_active {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -10px;
  left: 0;
  background: rgba(241, 243, 246, 0.64);
  backdrop-filter: blur(16px);
  z-index: 10;
}
